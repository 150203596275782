.experience .content {
  padding: 1rem;
}

.experience .content #workImg {
  width: 5rem;
}

h5 {
  text-align: center;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: black;
}